import { DeliveryClient } from "@kentico/kontent-delivery";
import { CacheService } from "~deliveryclientruntime/cache-service";
let config = {
  kenticokontent: {
    projectId: "",
    globalQueryConfig: {}
  }
};
try {
  config = require("./kenticokontent-config.js");
  config = config.default || config;
} catch (error) {
}
const deliveryClientPlugin = (context, inject) => {
  var kcSourceHeader = { header: "X-KC-SOURCE", value: "kentico-kontent-nuxt-module" };
  if (config.kenticokontent.globalQueryConfig) {
    config.kenticokontent.globalQueryConfig = Object.assign({}, config.kenticokontent.globalQueryConfig, {
      customHeaders: [
        kcSourceHeader
      ]
    });
  } else {
    config.kenticokontent = Object.assign({}, config.kenticokontent, {
      globalQueryConfig: {
        customHeaders: [
          kcSourceHeader
        ]
      }
    });
  }
  const deliveryClient = new DeliveryClient(config.kenticokontent);
  const cacheService = new CacheService(deliveryClient);
  const nuxtDeliveryClient = deliveryClient;
  nuxtDeliveryClient.viaCache = (query, seconds, cacheKey, isServerProcess) => cacheService.viaCache(query, seconds, cacheKey, isServerProcess);
  inject("nuxtDeliveryClient", nuxtDeliveryClient);
};
export default deliveryClientPlugin;
