
import Vue from 'vue';


Vue.directive('scroll-animation', {
  bind(el, binding, vNode) {
    const { eleClass, reset=()=>{} } = binding.value;
    const animtedScrollObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            window.setTimeout(()=>{
              if (entry.isIntersecting) {
                reset();
                console.log('entry.target >>', entry.target);
                entry.target.classList.add('enter');   
                entry.target.classList.remove('before-enter');
          
              } else {
                entry.target.classList.add('before-enter');
                entry.target.classList.remove('enter');
              }
            }, 10);
          });
        }
    );
    
    animtedScrollObserver.observe(el);
    window.setTimeout(()=>{
      
      const ele = document.querySelector(`.${eleClass}`);
      reset();
      ele.classList.add('before-enter');
      ele.classList.remove('enter');
    }, 0);


  },

  unbind(el) {
    // Remove Event Listeners
    
  },
});
