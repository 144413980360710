<template>
  <div :class="accordionCSSClass">
    <button :class="triggerCSSClass" @click="toggleAccordion"><slot name="trigger" /></button>
    <div :class="panelCSSClass"><slot name="content" /></div>
  </div>
</template>

<script>
import { LG } from '~/utils/constants';
import { clickedFaq } from '~/utils/gtm-events.js';

export default {
  props: {
    triggerClass: { type: String, default: '' },
    triggerActiveClass: { type: String, default: '' },
    panelClass: { type: String, default: '' },
    panelActiveClass: { type: String, default: '' },
    title: { type: String, default: '' },
    initOpen: { type: Boolean, default: false },
    disableLg: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    onChange: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      disabled: false,
      active: false,
      lg: false,
    };
  },
  computed: {
    accordionCSSClass() {
      return {
        'accordion': true,
        'accordion--disable-lg': this.disableLg && this.lg,
      };
    },
    triggerCSSClass() {
      return {
        'accordion__trigger': true,
        [this.triggerClass]: true,
        'accordion__trigger--active': this.active,
        [this.triggerActiveClass]: this.active,
      };
    },
    panelCSSClass() {
      return {
        'accordion__panel': true,
        [this.panelClass]: true,
        'accordion__panel--active': this.active,
        [this.panelActiveClass]: this.active,
      };
    },
  },
  watch: {
    lg(newValue, oldValue) {
      if (this.disableLg) {
        if (newValue !== oldValue) {
          if (newValue === true) {
            this.disabled = true;
            this.openAccordion();
          } else {
            this.disabled = false;
            this.closeAccordion();
          }
        }
      }
    },
    isActive(newValue) {
      this.active = newValue;
    },
  },
  mounted() {
    if (this.disableLg) {
      this.mql = window.matchMedia(`(min-width: ${LG}px)`);
      this.mql.addListener(this.handleMQswitch);
      this.handleMQswitch(this.mql);
    }

    if (this.isActive) {
      this.active = true;
    }
  },
  methods: {
    handleMQswitch(mql) {
      if (mql.matches) {
        this.lg = true;
      } else {
        this.lg = false;
      }
    },
    toggleAccordion() {
      if (!this.disabled) {
        if (!this.active) {
          this.openAccordion();
        } else {
          this.closeAccordion();
        }

        if (typeof this.onChange !== 'undefined') {
          this.onChange(this.active);
        }
      }
      clickedFaq(this.$gtm, this.title);
    },
    openAccordion() {
      this.active = true;
    },
    closeAccordion() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_accordion.scss';
</style>
