<template>
  <img :id="id" :key="id" data-sizes="auto" :data-src="imageSizedUrl" :alt="altHandleNull" class="lazyload">
</template>

<script>

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    altHandleNull() {
      return this.alt && this.alt.length > 0 && this.alt !== 'null' ? this.alt : '';
    },
    imageSizedUrl() {

      let src = `${this.src}?`;

      if (this.size) {

        const size = sizes[this.size];

        if (size.height) {
          src = `${src}height=${size.height}&`;
        }

        if (size.width) {
          src = `${src}width=${size.width}&`;
        }

        if (size.format) {
          src = `${src}fm=${size.format}&`;
        }

        if (size.fit) {
          src = `${src}fit=${size.fit}&`;
        }

      }

      src = `${src}auto=format`;

      return src;
    },
  },
};

const sizes = {
  general: {
    width: 1200,
    height: 1000,
    fit: 'crop',
  },
  background: {
    width: 2400,
  },
};
</script>
