<template>
  <p class="text-center">Oops... {{ message }}!<br> <a class="btn" href="/">Go back home</a></p>
</template>

<script>
export default {
  props: {
    message: { type: String, required: true },
    statusCode: { type: Number, required: true },
  },
};
</script>
