function processUrl(item) {
  if (item.redirectToUrl.length > 0) {
    const redirectUrl = item.redirectToUrl;

    return {
      url: redirectUrl,
      external: true,
    };
  }

  if (item.contentItem && item.contentItem.slug) {
    return {
      url: item.contentItem.slug,
      external: false,
    };
  }

  return false;
}

function processItem(items, depthLimit = 6, depth = 1) {
  if (depth > depthLimit) {
    return [];
  }

  const menuItems = items.reduce((store, item) => {
    const urlObj = processUrl(item);

    if (urlObj) {
      const { external, url } = urlObj;
      let children = [];

      if (typeof item.subitems !== 'undefined' && item.subitems.length > 0) {
        children = processItem(item.subitems, depthLimit, depth + 1);
      }

      const { title, secondaryText, tertiaryText, image, isOverlayItem, subtext } = item;
      return [
        ...store,
        {
          url,
          external,
          children,
          title,
          secondaryText,
          tertiaryText,
          image,
          isOverlayItem: isOverlayItem.length > 0 && isOverlayItem[0].codename == 'overlayItem',
          subtext,
        },
      ];
    }
    return store;
  }, []);

  return menuItems;
}

export default function processNavigation(navigation, depthLimit) {
  return processItem(navigation.subitems, depthLimit);
}
