import { SITE_WIDE_CONTENT_LOAD } from './sitewide';
import { validPath } from '~/utils/data';

const cookieparser = process.server ? require('cookieparser') : undefined;

export const DEFAULT_LANGUAGE = 'en-GB';

export const state = () => ({
  sitewide: {},
});

export const actions = {
  async nuxtServerInit({ dispatch }, { req }) {
    // TODO why is req undefined when building app?
    if (req && validPath(req.url)) {
      let cookies = {};

      if (req.headers.cookie) {
        cookies = cookieparser.parse(req.headers.cookie);
      }

      await dispatch(`sitewide/${SITE_WIDE_CONTENT_LOAD}`, {
        language: DEFAULT_LANGUAGE,
        cookies,
      });
    }
  },
};
