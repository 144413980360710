import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21bcc43f = () => interopDefault(import('../pages/enquiry-form/index.vue' /* webpackChunkName: "pages/enquiry-form/index" */))
const _c2a3cde8 = () => interopDefault(import('../pages/modules/index.vue' /* webpackChunkName: "pages/modules/index" */))
const _0d8d35c6 = () => interopDefault(import('../pages/modules/blog_listing.vue' /* webpackChunkName: "pages/modules/blog_listing" */))
const _7ad1414e = () => interopDefault(import('../pages/modules/co_working_listing.vue' /* webpackChunkName: "pages/modules/co_working_listing" */))
const _61c93eab = () => interopDefault(import('../pages/modules/private_hire_listing.vue' /* webpackChunkName: "pages/modules/private_hire_listing" */))
const _f943f612 = () => interopDefault(import('../pages/modules/rooms_listing.vue' /* webpackChunkName: "pages/modules/rooms_listing" */))
const _86350d8e = () => interopDefault(import('../pages/modules/rooms_pricing.vue' /* webpackChunkName: "pages/modules/rooms_pricing" */))
const _b1252276 = () => interopDefault(import('../pages/modules/_module.vue' /* webpackChunkName: "pages/modules/_module" */))
const _646635a9 = () => interopDefault(import('../pages/book/_type/_location/index.vue' /* webpackChunkName: "pages/book/_type/_location/index" */))
const _621a7394 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2b0c3cbe = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/enquiry-form",
    component: _21bcc43f,
    name: "enquiry-form"
  }, {
    path: "/modules",
    component: _c2a3cde8,
    name: "modules"
  }, {
    path: "/modules/blog_listing",
    component: _0d8d35c6,
    name: "modules-blog_listing"
  }, {
    path: "/modules/co_working_listing",
    component: _7ad1414e,
    name: "modules-co_working_listing"
  }, {
    path: "/modules/private_hire_listing",
    component: _61c93eab,
    name: "modules-private_hire_listing"
  }, {
    path: "/modules/rooms_listing",
    component: _f943f612,
    name: "modules-rooms_listing"
  }, {
    path: "/modules/rooms_pricing",
    component: _86350d8e,
    name: "modules-rooms_pricing"
  }, {
    path: "/modules/:module",
    component: _b1252276,
    name: "modules-module"
  }, {
    path: "/book/:type?/:location",
    component: _646635a9,
    name: "book-type-location"
  }, {
    path: "/",
    component: _621a7394,
    name: "index"
  }, {
    path: "/*",
    component: _2b0c3cbe,
    name: "all"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
