import { DeliveryClient, TypeResolver } from 'kentico-cloud-delivery';

const projectId = process.env.KENTICO_PROJECT_ID;

function setupClient(models = []) {
  const typeResolvers = models.reduce((store, { model: Model, contentType }) => {
    if (Model) {
      return [...store, new TypeResolver(contentType, () => new Model())];
    }

    return store;
  }, []);

  return new DeliveryClient({
    projectId,
    typeResolvers,
  });
}

export default setupClient;
