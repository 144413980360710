/* -----------------------------
NB - PLEASE KEEP THESE VALUES
IN SYNC WITH CONSTANTS-NODE.JS
----------------------------- */
export const XS = 0;
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;
export const XXL = 1500;
export const XXXL = 1920;

/* -----------------------------
NB - these values are referenced
in the CSS so please don't modify
----------------------------- */
export const BREAKPOINT_XS = 'xs';
export const BREAKPOINT_SM = 'sm';
export const BREAKPOINT_MD = 'md';
export const BREAKPOINT_LG = 'lg';
export const BREAKPOINT_XL = 'xl';
export const BREAKPOINT_XXL = 'xxl';
export const BREAKPOINT_XXXL = 'xxxl';
/* --------------------------- */

export const BREAKPOINTS = {
  [BREAKPOINT_XS]: XS,
  [BREAKPOINT_SM]: SM,
  [BREAKPOINT_MD]: MD,
  [BREAKPOINT_LG]: LG,
  [BREAKPOINT_XL]: XL,
  [BREAKPOINT_XXL]: XXL,
  [BREAKPOINT_XXXL]: XXXL,
};

export const RECURSIVE_DEPTH_LIMIT = 7;
export const API_DEPTH = 6;

/**
 * booking side panel tab names
 */
export const TABNAME_LONG_STAY = 'long';
export const TABNAME_SHORT_STAY = 'short';

export const LOCATION_CANARY_WHARF = 'Canary Wharf';
export const LOCATION_OLD_OAK = 'Old Oak';
export const LOCATION_PAPER_FACTORY = 'Paper Factory';

export const totesRando = (min, max) => {
  return Math.floor(Math.random() * (1 + max - min) + min);
};
