import { SortOrder } from 'kentico-cloud-delivery';
import setupClient from '~/kentico';

import { linkProcessor } from '~/utils/processor';

export const SINGLE = 'SINGLE';
export const MULTIPLE = 'MULTIPLE';
export const SLUG_FIELD = 'slug';
export const ERRORS = {
  LANGUAGE: 'Langauge must be suppled for a API request',
  CONTENT_ITEM: 'Content item name must be suppled to get a single item',
  CONTENT_TYPE: 'Content type name must be suppled to get a list of items',
};

// Call apiCall() to query via the Kentico Delivery API https://developer.kenticocloud.com/v1/reference#delivery-api

function hasRequiredSingleParams(params) {
  const { contentItem, language, slug } = params;

  if (language && (contentItem || slug)) {
    return true;
  }

  return false;
}

function hasRequiredMultipleParams(params) {
  const { contentType, language } = params;

  if (contentType && language) {
    return true;
  }

  return false;
}

function modifierKeyFilter(key) {
  return `${key}Parameter`;
}

function addModifiers(request, modifiers) {
  const newRequest = Object.assign(request, {});

  Object.keys(modifiers).forEach((key) => {
    const item = modifiers[key];
    const modifierKey = modifierKeyFilter(key);

    switch (key) {
      case 'order': {
        if (item.key && item.direction) {
          newRequest[modifierKey](`elements.${item.key}`, SortOrder[item.direction]);
        }
        break;
      }
      case 'filter': {
        if (item.key && item.value) {
          newRequest[item.type](`elements.${item.key}`, [item.value]);
        }
        break;
      }
      default: {
        newRequest[modifierKey](modifiers[key]);
        break;
      }
    }
  });

  return newRequest;
}

function handleResponse(response, dataProcessor, debug = false) {
  if (debug) {
    console.log('DeliveryClient Response', response);
  }

  const modularContent = response.debug.response.data.modular_content;
  // const { debug: debugObj, ...rest } = response;
  const { ...rest } = response;

  const responseObj = {
    ...rest,
    modularContent,
  };

  if (dataProcessor) {
    return dataProcessor(responseObj);
  }

  return responseObj;
}

function singleRequest(client, params, debug = false) {
  return new Promise((resolve, reject) => {
    const { contentItem, slug, dataProcessor, language, modifiers } = params;

    if (hasRequiredSingleParams(params)) {
      let request = null;

      if (slug) {
        request = client
            .items()
            .languageParameter(language)
            .equalsFilter(`elements.${SLUG_FIELD}`, slug);
      } else if (contentItem) {
        request = client.item(contentItem).languageParameter(language);
      }

      request.queryConfig({
        linkResolver: linkProcessor,
      });

      if (modifiers) {
        request = addModifiers(request, modifiers);
      }

      if (debug) {
        console.log('Request', request);
      }

      request
          .getPromise()
          .then((response) => {
            let returnResponse = response;

            if (slug) {
              if (!response.isEmpty) {
                returnResponse = {
                  item: response.items[0],
                  debug: response.debug,
                };
              } else {
              // eslint-disable-next-line no-throw-literal
                throw {
                  errorCode: 100,
                };
              }

              return resolve(handleResponse(returnResponse, dataProcessor, debug));
            }

            return resolve(handleResponse(returnResponse, dataProcessor, debug));
          })
          .catch((error) => {
            reject(error);
          });
    } else {
      if (!contentItem) {
        reject(new Error(ERRORS.CONTENT_ITEM));
      }
      if (!language) {
        reject(new Error(ERRORS.LANGUAGE));
      }
    }
  });
}

function multipleRequest(client, params, debug = false) {
  return new Promise((resolve, reject) => {
    const { contentType, dataProcessor, language, modifiers } = params;

    if (hasRequiredMultipleParams(params)) {
      let request = client
          .items()
          .languageParameter(language)
          .type(contentType)
          .queryConfig({
            linkResolver: linkProcessor,
          });

      if (modifiers) {
        request = addModifiers(request, modifiers);
      }

      request
          .getPromise()
          .then((response) => resolve(handleResponse(response, dataProcessor, debug)))
          .catch((error) => reject(error));
    } else {
      if (!contentType) {
        reject(new Error(ERRORS.CONTENT_TYPE));
      }
      if (!language) {
        reject(new Error(ERRORS.LANGUAGE));
      }
    }
  });
}

export function apiCall(options = {}, debug = false) {
  const { type = SINGLE, models = [], ...params } = options;
  const client = setupClient(models);

  const requestParams = {
    language: 'en-GB',
    ...params,
  };

  switch (type) {
    case SINGLE: {
      return singleRequest(client, requestParams, debug);
    }
    case MULTIPLE: {
      return multipleRequest(client, requestParams, debug);
    }
    default: {
      return multipleRequest(client, requestParams, debug);
    }
  }
}
