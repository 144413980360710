<template>
  <ErrorModule :message="error.message" :status-code="error.statusCode" />
</template>

<script>
import ErrorModule from '~/components/modules/ErrorModule.vue';

export default {
  components: {
    ErrorModule,
  },
  props: {
    error: {
      type: Object,
      default() {
        return {
          message: { type: String, required: true },
          statusCode: { type: Number, required: true },
        };
      },
    },
  },
};
</script>
