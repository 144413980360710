<template>
  <picture>
    <source v-for="(image, index) in responsiveImages" :key="index" :data-srcset="image.srcset" :media="`(min-width: ${image.media}px)`">
    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="defaultImage" :alt="altHandleNull" class="lazyload" @load="onload">
  </picture>
</template>

<script>

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    sizes: {
      type: Object,
      required: false,
      default: null,
    },
    onload: {
      type: Function,
      required: false,
      default: function() {},
    },
  },
  computed: {
    responsiveImages() {
      return this.sizes && this.sizes.sizes && Array.isArray(this.sizes.sizes) ?
          this.sizes.sizes.map((val) => ({ srcset: this.getSizedImageSrcset(this.src, val.width, val.height), media: val.breakpoint }))
              .sort((a, b) => a.media < b.media ? 1 : -1) : null;
    },
    defaultImage() {
      return this.sizes && this.sizes.default && Array.isArray(this.sizes.default) ?
        this.getSizedImageSrcset(this.src, this.sizes.default[0], this.sizes.default[1]) : null;
    },
    altHandleNull() {
      return this.alt && this.alt.length > 0 && this.alt !== 'null' ? this.alt : '';
    },
  },
  methods: {
    getSizedImageSrcset(src, w, h) {
      return `${src}?width=${w}&height=${h}&fit=crop, ${src}?width=${w*2}&height=${h*2}&fit=crop%20x2`;
    },
  },
};
</script>
