/* eslint-disable no-param-reassign */
/* eslint-disable */

import NavigationItemModel from '~/models/NavigationItem';
import Address from '~/models/Address';
import HeaderOptions from '~/models/HeaderOptions';

import { apiCall, SINGLE } from '~/api';
import processNavigation from '~/utils/navigation';

import { processSingle } from '~/utils/processor';
import { DEFAULT_LANGUAGE } from './index';

import { API_DEPTH } from '~/utils/constants';

const Cookie = process.client ? require('js-cookie') : undefined;

export const NAMESPACE = 'sitewide';
export const SITE_WIDE_CONTENT_LOAD = 'SITE_WIDE_CONTENT_LOAD';
export const SITE_WIDE_CONTENT_LOADED = 'SITE_WIDE_CONTENT_LOADED';
export const SITE_WIDE_CONTENT_FAILED = 'SITE_WIDE_CONTENT_FAILED';
export const OPEN_PRIMARY_NAVIGATION = 'OPEN_PRIMARY_NAVIGATION';
export const CLOSE_PRIMARY_NAVIGATION = 'CLOSE_PRIMARY_NAVIGATION';
export const OPEN_PRIMARY_NAVIGATION_LEVEL = 'OPEN_PRIMARY_NAVIGATION_LEVEL';
export const RESET_PRIMARY_NAVIGATION = 'RESET_PRIMARY_NAVIGATION';
export const UPDATE_LAST_PATH = 'UPDATE_LAST_PATH';
export const SET_INITIAL_COOKIE_STATE = 'SET_INITIAL_COOKIE_STATE';
export const ACCEPT_COOKIE_BANNER = 'ACCEPT_COOKIE_BANNER';
export const PRIMARY_NAVIGATION_PRIMARY_CONTENT_ITEM = 'primary_navigation___primary';
export const PRIMARY_NAVIGATION_SECONDARY_CONTENT_ITEM = 'primary_navigation___secondary';
export const FOOTER_MENU_ONE_ITEM = 'footer___menu_one';
export const FOOTER_MENU_TWO_ITEM = 'footer___menu_two';
export const FOOTER_MENU_THREE_ITEM = 'footer___menu_three';
export const SIDEWIDE_CONTENT_CONTENT_ITEM = 'sitewide_content';
export const COOKIE_BANNER_ACCEPTED = 'cookieBannerAccepted';
export const SET_CRISIS_NOTIFICATION_ALERT = 'SET_CRISIS_NOTIFICATION_ALERT';
export const SET_WHITE_HEADER = 'SET_WHITE_HEADER';

export const state = () => ({
  companyName: 'The Collective',
  sitewideMetaDescription: '',
  lastPath: '',
  home: '/',
  languages: {
    current: DEFAULT_LANGUAGE,
    options: [
      {
        key: 'en-GB',
        title: 'UK / EN',
        href: '/uk-en',
      },
      {
        key: 'de-GER',
        title: 'DE / GER',
        href: '/de-ger',
      },
    ],
  },
  address: {
    org: '',
    addressLineOne: '',
    addressLineTwo: '',
    locality: '',
    region: '',
    postcode: '',
    country: '',
    email: '',
    tel: '',
  },
  copyright: '',
  socialMedia: [],
  header: {
    logoAltTag: 'The Collective',
    showPrimaryNavigationText: 'Show primary navigation',
    closePrimaryNavigationText: 'Close primary navigation',
    primaryNavigationPrimary: {
      items: [],
      open: false,
      active: [
        // This is an array of ojects for if we ever needed multiple primary navigation items open at once, or if we introduced a third level
        // {
        //   depth: 0,
        //   index: 1,
        // }
      ],
    },
    primaryNavigationSecondary: [],
    book: {
      text: '',
      url: '',
    },
    backToMenuText: 'Back to menu',
    bookingHeaderBackText: 'Back',
    isWhiteHeader: false,
  },
  newsletter: {
    title: '',
    userId: '',
    listId: '',
    emailFieldPlaceholder: '',
    submitButtonText: '',
  },
  footer: {
    backToTopText: '',
    backToTopAnchor: '',
    menuOneTitle: '',
    menuTwoTitle: '',
    menuThreeTitle: '',
    contactDetailsTitle: '',
    menuOneItems: [],
    menuTwoItems: [],
    menuThreeItems: [],
  },
  cookieBanner: {
    body: '',
    closeButtonText: '',
    accepted: false,
  },
  bookingDrawer: {
    bookingCtaLongStay: '',
    bookingCtaExtraLongStay: '',
    bookingCtaShortStay: '',
  },
  crisisNotificationAlert: {
    body: '',
    ctaLabel: '',
    ctaLink: '',
  },
});

export const actions = {
  async [SITE_WIDE_CONTENT_LOAD]({ commit }, { language = DEFAULT_LANGUAGE, cookies }) {
    try {
      const sitewideContent = await apiCall({
        type: SINGLE,
        contentItem: SIDEWIDE_CONTENT_CONTENT_ITEM,
        language,
        modifiers: {
          depth: API_DEPTH,
        },
        models: [
          {
            contentType: 'header_options',
            model: HeaderOptions,
          },
          {
            contentType: 'address',
            model: Address,
          },
          {
            contentType: 'navigation_item',
            model: NavigationItemModel,
          },
        ],
        dataProcessor: data => {
          return processSingle(data);
        },
      });

      const {
        companyName,
        sitewideMetaDescription,
        address,
        header,
        footer,
        socialMedia,
        cookieBanner,
        bookingLocations,
        enquiryForm,
        bookingCtaLongStay,
        bookingCtaExtraLongStay,
        bookingCtaShortStay,
      } = sitewideContent.content;

      const {
        logoAltTag,
        bookText,
        bookPage,
        showPrimaryNavigationText,
        closePrimaryNavigationText,
        backToMenuText,
        primaryNavigationPrimary,
        primaryNavigationSecondary,
        bookingHeaderBackText,
      } = header;

      const {
        copyright,
        newsletterSignup,
        backToTopText,
        backToTopAnchor,
        menuOneTitle,
        menuTwoTitle,
        menuThreeTitle,
        contactDetailsTitle,
        menuOne: footerMenuOne,
        menuTwo: footerMenuTwo,
        menuThree: footerMenuThree,
      } = footer;

      const { title, userId, listId, emailFieldPlaceholder, submitButtonText } = newsletterSignup;

      const addressVcard = {
        org: address.company,
        addressLineOne: address.addressLineOne,
        addressLineTwo: address.addressLineTwo,
        region: address.region,
        locality: address.city,
        postcode: address.postcode,
        country: address.company,
        email: address.email,
        tel: address.phoneNumber,
      };

      const processedPrimaryNavigationPrimary = processNavigation(primaryNavigationPrimary, 5);
      const processedPrimaryNavigationSecondary = processNavigation(primaryNavigationSecondary, 5);
      const processedFooterMenuOne = processNavigation(footerMenuOne, 5);
      const processedFooterMenuTwo = processNavigation(footerMenuTwo, 5);
      const processedFooterMenuThree = processNavigation(footerMenuThree, 5);

      commit(SITE_WIDE_CONTENT_LOADED, {
        companyName,
        sitewideMetaDescription,
        primaryNavigationPrimary: processedPrimaryNavigationPrimary,
        primaryNavigationSecondary: processedPrimaryNavigationSecondary,
        address: addressVcard,
        header: {
          logoAltTag,
          book: {
            text: bookText,
            url: bookPage.slug,
          },
          showPrimaryNavigationText,
          closePrimaryNavigationText,
          backToMenuText,
          bookingHeaderBackText,
          isWhiteHeader: false,
        },
        copyright,
        footer: {
          backToTopText,
          backToTopAnchor,
          menuOneTitle,
          menuTwoTitle,
          menuThreeTitle,
          contactDetailsTitle,
          menuOneItems: processedFooterMenuOne,
          menuTwoItems: processedFooterMenuTwo,
          menuThreeItems: processedFooterMenuThree,
        },
        newsletter: {
          title,
          userId,
          listId,
          emailFieldPlaceholder,
          submitButtonText,
        },
        bookingLocations,
        enquiryForm,
        socialMedia,
        cookieBanner,
        bookingDrawer: {
          bookingCtaLongStay,
          bookingCtaExtraLongStay,
          bookingCtaShortStay,
        },
      });

      commit(SET_INITIAL_COOKIE_STATE, {
        cookies,
      });
    } catch (error) {
      console.error(error);
      // commit(SITE_WIDE_CONTENT_FAILED, error);
    }
  },
  [OPEN_PRIMARY_NAVIGATION]({ commit }) {
    commit(OPEN_PRIMARY_NAVIGATION);
  },
  [CLOSE_PRIMARY_NAVIGATION]({ commit }) {
    commit(CLOSE_PRIMARY_NAVIGATION);
    commit(RESET_PRIMARY_NAVIGATION);
  },
  [OPEN_PRIMARY_NAVIGATION_LEVEL]({ commit }, { index, depth }) {
    commit(OPEN_PRIMARY_NAVIGATION);
    commit(OPEN_PRIMARY_NAVIGATION_LEVEL, { index, depth });
  },
  [RESET_PRIMARY_NAVIGATION]({ commit }) {
    commit(RESET_PRIMARY_NAVIGATION);
  },
  [UPDATE_LAST_PATH]({ commit }, { path }) {
    commit(UPDATE_LAST_PATH, { path });
  },
  [SET_CRISIS_NOTIFICATION_ALERT]({ commit }, { crisisNotificationAlert }) {
    commit(SET_CRISIS_NOTIFICATION_ALERT, { crisisNotificationAlert });
  },
  [SET_WHITE_HEADER]({ commit }, isWhiteHeader = false) {
    commit(SET_WHITE_HEADER, isWhiteHeader);
  },
  [ACCEPT_COOKIE_BANNER]({ commit }) {
    commit(ACCEPT_COOKIE_BANNER);
  },
};

export const mutations = {
  [SITE_WIDE_CONTENT_LOADED](newState, content) {
    newState.header = {
      ...newState.header,
      ...content.header,
      primaryNavigationPrimary: {
        ...newState.header.primaryNavigationPrimary,
        items: content.primaryNavigationPrimary,
      },
      primaryNavigationSecondary: content.primaryNavigationSecondary,
    };

    newState.footer = {
      ...newState.footer,
      ...content.footer,
    };

    newState.newsletter = {
      ...newState.newsletter,
      ...content.newsletter,
    };

    newState.companyName = content.companyName;
    newState.sitewideMetaDescription = content.sitewideMetaDescription;
    newState.address = content.address;
    newState.socialMedia = content.socialMedia;
    newState.bookingLocations = content.bookingLocations;
    newState.enquiryForm = content.enquiryForm;
    newState.copyright = content.copyright;
    newState.cookieBanner = {
      ...newState.cookieBanner,
      ...content.cookieBanner,
    };
    newState.bookingDrawer = content.bookingDrawer;
  },
  [OPEN_PRIMARY_NAVIGATION](newState) {
    newState.header.primaryNavigationPrimary.open = true;
  },
  [CLOSE_PRIMARY_NAVIGATION](newState) {
    newState.header.primaryNavigationPrimary.open = false;
  },
  [OPEN_PRIMARY_NAVIGATION_LEVEL](newState, { index, depth }) {
    newState.header.primaryNavigationPrimary.active = [
      {
        index,
        depth,
      },
    ];
  },
  [RESET_PRIMARY_NAVIGATION](newState) {
    newState.header.primaryNavigationPrimary.active = [];
  },
  [UPDATE_LAST_PATH](newState, { path }) {
    newState.lastPath = path;
  },
  [SET_CRISIS_NOTIFICATION_ALERT](newState, { crisisNotificationAlert }) {
    newState.crisisNotificationAlert = crisisNotificationAlert;
  },

  [SET_WHITE_HEADER](newState, isWhiteHeader) {
    newState.header.isWhiteHeader = isWhiteHeader;
  },
  [SET_INITIAL_COOKIE_STATE](newState, { cookies }) {
    if (typeof cookies !== 'undefined') {
      if (cookies[COOKIE_BANNER_ACCEPTED]) {
        newState.cookieBanner.accepted = true;
      }
    }
  },
  [ACCEPT_COOKIE_BANNER](newState) {
    newState.cookieBanner.accepted = true;
    if (typeof Cookie !== 'undefined') {
      Cookie.set(COOKIE_BANNER_ACCEPTED, true);
    } else {
      console.error("Can't set cookie on server");
    }
  },
};

export const getters = {
  companyName: stateObj => stateObj.companyName,
  metaDescription: stateObj => stateObj.sitewideMetaDescription,
  primaryNavigationOpen: stateObj => stateObj.header.primaryNavigationPrimary.open,
  primaryNavigationSecondLevel: stateObj => {
    const { items, active } = stateObj.header.primaryNavigationPrimary;

    if (active.length > 0 && typeof active[0] !== 'undefined') {
      const { index } = active[0];

      if (typeof items[index] !== 'undefined' && typeof items[index].children !== 'undefined') {
        return items[index].children;
      }
    }

    return [];
  },
  primaryItemSecondLevelMeta: stateObj => {
    const { items, active } = stateObj.header.primaryNavigationPrimary;
    if (active.length > 0 && typeof active[0] !== 'undefined') {
      const { index } = active[0];

      if (typeof items[index] !== 'undefined' && typeof items[index].children !== 'undefined') {
        const { title = '', secondaryText = '' } = items[index];
        return { title, secondaryText };
      }
    }
    return {};
  },
  primaryNavigation: stateObj => stateObj.header.primaryNavigationPrimary.items,
  languages: stateObj => stateObj.languages.options,
  currentLanguage: stateObj => {
    return stateObj.languages.options.find(language => language.key === stateObj.languages.current);
  },
  address: stateObj => stateObj.address,
  primaryNavigationSecondary: stateObj => stateObj.header.primaryNavigationSecondary,
  primaryNavigationTopLevel: stateObj => stateObj.header.primaryNavigationPrimary.items,
  book: stateObj => stateObj.header.book,
  home: stateObj => stateObj.home,
  logoAltTag: stateObj => stateObj.header.logoAltTag,
  showPrimaryNavigationText: stateObj => stateObj.header.showPrimaryNavigationText,
  closePrimaryNavigationText: stateObj => stateObj.header.closePrimaryNavigationText,
  backToMenuText: stateObj => stateObj.header.backToMenuText,
  newsletterTitle: stateObj => stateObj.newsletter.title,
  newsletterUserId: stateObj => stateObj.newsletter.userId,
  newsletterListId: stateObj => stateObj.newsletter.listId,
  newsletterEmailFieldPlaceholder: stateObj => stateObj.newsletter.emailFieldPlaceholder,
  newsletterSubmitButtonText: stateObj => stateObj.newsletter.submitButtonText,
  footerBackToTopText: stateObj => stateObj.footer.backToTopText,
  footerBackToTopAnchor: stateObj => stateObj.footer.backToTopAnchor,
  footerMenuOneTitle: stateObj => stateObj.footer.menuOneTitle,
  footerMenuOneItems: stateObj => stateObj.footer.menuOneItems,
  footerMenuTwoTitle: stateObj => stateObj.footer.menuTwoTitle,
  footerMenuTwoItems: stateObj => stateObj.footer.menuTwoItems,
  footerMenuThreeTitle: stateObj => stateObj.footer.menuThreeTitle,
  footerMenuThreeItems: stateObj => stateObj.footer.menuThreeItems,
  footerContactDetailsTitle: stateObj => stateObj.footer.contactDetailsTitle,
  copyright: stateObj => stateObj.copyright,
  socialMedia: stateObj => stateObj.socialMedia,
  bookingLocations: stateObj => stateObj.bookingLocations,
  enquiryForm: stateObj => stateObj.enquiryForm,
  lastPath: stateObj => stateObj.lastPath,
  bookingHeaderBackText: stateObj => stateObj.header.bookingHeaderBackText,
  cookieBannerBody: stateObj => stateObj.cookieBanner.body,
  cookieBannerCloseButtonText: stateObj => stateObj.cookieBanner.closeButtonText,
  cookieBannerAccepted: stateObj => stateObj.cookieBanner.accepted,
  bookingCtaLongStay: stateObj => stateObj.bookingDrawer.bookingCtaLongStay,
  bookingCtaExtraLongStay: stateObj => stateObj.bookingDrawer.bookingCtaExtraLongStay,
  bookingCtaShortStay: stateObj => stateObj.bookingDrawer.bookingCtaShortStay,
  crisisNotificationAlert: stateObj => stateObj.crisisNotificationAlert,
  isWhiteHeader: stateObj => stateObj.header.isWhiteHeader,
};
