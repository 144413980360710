import Vue from 'vue';

import Image from '~/components/elements/Image.vue';
Vue.component('KenticoImage', Image);

import ResponsiveImage from '~/components/elements/ResponsiveImage.vue';
Vue.component('KenticoResponsiveImage', ResponsiveImage);

import Arrow from '~/assets/icons/arrow-right.svg?inline';
Vue.component('Arrow', Arrow);

import Chevron from '~/assets/icons/chevron.svg?inline';
Vue.component('Chevron', Chevron);

import ChevronRounded from '~/assets/icons/chevron-rounded.svg?inline';
Vue.component('ChevronRounded', ChevronRounded);

import Accordion from '~/components/components/Accordion';
Vue.component('Accordion', Accordion);

import DestinationEssentialsCarousel from '~/components/modules/DestinationEssentialsCarousel';
Vue.component('DestinationEssentialsCarousel', DestinationEssentialsCarousel);
