import { getSlug } from '~/utils/data';

export default function(context) {
  const slug = getSlug(context.route.path);

  switch (slug) {
    case '/': {
      context.contentItem = 'homepage';
      break;
    }
    case 'book': {
      context.contentItem = 'homepage';
      break;
    }
    case 'thank-you-canary-wharf': {
      context.contentItem = 'homepage';
      break;
    }
    case 'thank-you-old-oak': {
      context.contentItem = 'homepage';
      break;
    }
    case 'thank-you-harrow': {
      context.contentItem = 'homepage';
      break;
    }
    default: {
      context.contentItem = undefined;
      break;
    }
  }
}
