const MULTIPLE = 'MULTIPLE';
const PAGE = 'PAGE';
const POST = 'POST';

const templates = [
  {
    name: 'Homepage',
    key: 'homepage',
    modules: ['co_living_highlights'],
    examplePage: '',
    contentType: 'template___homepage',
    type: PAGE,
  },
  {
    name: 'Co Living',
    key: 'co_living',
    modules: [],
    examplePage: '',
    contentType: 'template___co_living',
    type: PAGE,
  },
  {
    name: 'About',
    key: 'about',
    modules: ['location_hero', 'anchor_links', 'our_values', 'what_we_do'],
    examplePage: 'about-us',
    contentType: 'template___about',
    type: PAGE,
  },
  {
    name: 'Location',
    key: 'location',
    modules: [
      'location_hero',
      'anchor_links',
      'terms_and_conditions',
      'building_plan',
      'request_interest',
      'events_listing',
      'rooms_listing',
      'rooms_pricing',
      'restaurants_listing',
      'find_us',
      'co_working',
      'faqs',
    ],
    examplePage: 'old-oak',
    prependSlug: 'locations/',
    contentType: 'template___location',
    type: POST,
  },
  {
    name: 'Destination',
    key: 'Destination',
    modules: ['destination_hero', 'destination_divider', 'destination_intro', 'destination_essentials', 'destination_members_top_picks'],
    examplePage: 'east-london',
    prependSlug: 'destination/',
    contentType: 'template___destination',
    type: POST,
  },
  {
    name: 'The Journal',
    key: 'blog',
    modules: ['blog_listing'],
    examplePage: '',
    contentType: 'template___blog',
    additionalRequests() {
      return [
        {
          moduleKey: 'blogListing',
          injectKey: 'items',
          contentType: 'template___blog_post',
          options: {
            type: MULTIPLE,
            modifiers: {
              limit: 6,
              order: {
                key: 'date',
                direction: 'desc',
              },
            },
          },
        },
      ];
    },
    type: PAGE,
    archiveFor: 'template___blog_post',
  },
  {
    name: 'Blog Post',
    key: 'blog_post',
    modules: ['blog_article'],
    examplePage: '',
    prependSlug: 'the-journal/',
    contentType: 'template___blog_post',
    type: POST,
  },
  {
    name: 'FAQS',
    key: 'faqs',
    modules: ['faqs'],
    examplePage: 'faqs',
    contentType: 'template___faqs',
    type: PAGE,
  },
  {
    name: 'Careers',
    key: 'careers',
    modules: ['cta'],
    examplePage: 'careers',
    contentType: 'template___careers',
    type: PAGE,
  },
  {
    name: 'Privacy',
    key: 'privacy',
    modules: ['privacy'],
    examplePage: 'privacy',
    contentType: 'template___privacy',
    type: PAGE,
  },
  {
    name: 'Terms',
    key: 'terms',
    modules: ['terms_conditions'],
    examplePage: 'terms',
    contentType: 'template___terms',
    type: PAGE,
  },
  {
    name: 'Cookies',
    key: 'cookies',
    modules: ['cookie_policy'],
    examplePage: 'cookies',
    contentType: 'template___cookies',
    type: PAGE,
  },
  {
    name: 'Landing Page',
    key: 'landing_page',
    contentType: 'template___landing_page',
    type: PAGE,
  },
  {
    name: 'Landing Page',
    key: 'landing_page',
    contentType: 'template___landing_page_v2',
    type: PAGE,
  },
  {
    name: 'Enquiry Form',
    key: 'enquiry_form',
    contentType: 'template___enquiry_form',
    type: PAGE,
  },
];

export default templates;
