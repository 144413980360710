<template>
  <div :class="`destination-essentials--carousel-wrapper carousel-wrapper-${index}`">
    <div :ref="`carousel-${index}`" class="destination-essentials--carousel">
      <div v-for="(location, locationIndex) in essential.essentialsLocations" :key="locationIndex" class="destination-essentials--location">
        <div class="destination-essentials--location-image-wrapper">
          <a :href="location.googleMapLink" target="blank" class="destination-essentials--location-overlay">
            View on map
          </a>
          <img :src="location.image && location.image.url" class="destination-essentials--location-image">
        </div>
        <div class="destination-essentials--location-duration">{{ location.duration }}</div>
        <div class="destination-essentials--location-name">{{ location.location }}</div>
      </div>
    </div>
    <div class="destination-essentials--carousel-arrows"> 
      <div class="destination-essentials--arrow destination-essentials--arrow-right" @click="scrollRight" />
      <div class="destination-essentials--arrow destination-essentials--arrow-left" @click="scrollLeft" />
    </div>
  </div>
</template>
<script>

export default {
  name: 'DestinationEssentialsCarousel',
  props: {
    essential: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      required: true,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      carousel: '',
    };
  },
  mounted() {
    this.carousel = this.$refs[`carousel-${this.index}`];
    this.carousel.addEventListener('scroll', this.isScrolling);
    this.scrollLeft();
  },
  methods: {
    isScrolling(evt) {
      if (this.carousel.scrollLeft<=0) {
        this.scrolledToLeft();
      }
      if (this.carousel.scrollLeft>=this.carousel.scrollWidth - this.carousel.clientWidth) {
        this.scrolledToRight();
      }
    },
    scrolledToLeft() {
      document.querySelector(`.carousel-wrapper-${this.index} .destination-essentials--arrow-right`).classList.remove('hidden');
      document.querySelector(`.carousel-wrapper-${this.index} .destination-essentials--arrow-left`).classList.add('hidden');
    },
    scrolledToRight() {
      document.querySelector(`.carousel-wrapper-${this.index} .destination-essentials--arrow-right`).classList.add('hidden');
      document.querySelector(`.carousel-wrapper-${this.index} .destination-essentials--arrow-left`).classList.remove('hidden');
    },
    scrollRight() {
      const rightEdge = this.carousel.scrollWidth - this.carousel.clientWidth;
      this.carousel.scroll({
        left: rightEdge,
        top: 0,
        behavior: 'smooth',
      });

      this.scrolledToRight();
    },
    scrollLeft() {
      this.carousel.scroll({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
      this.scrolledToLeft();
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_destination-essentials.scss';
</style>
