import { ContentItem } from 'kentico-cloud-delivery';

/**
 * This class was generated by 'kentico-cloud-model-generator-utility' at Mon Jan 07 2019 11:30:12 GMT+0000 (Greenwich Mean Time).
 */
class HeaderOptions extends ContentItem {
  constructor() {
    super({
      propertyResolver: (fieldName) => {
        if (fieldName === 'book_text') {
          return 'bookText';
        }
        if (fieldName === 'book_page') {
          return 'bookPage';
        }
        if (fieldName === 'logo_alt_tag') {
          return 'logoAltTag';
        }
        if (fieldName === 'show_primary_navigation_text') {
          return 'showPrimaryNavigationText';
        }
        if (fieldName === 'close_primary_navigation_text') {
          return 'closePrimaryNavigationText';
        }
        if (fieldName === 'back_to_menu_text') {
          return 'backToMenuText';
        }
        return fieldName;
      },
    });
  }
}

export default HeaderOptions;
