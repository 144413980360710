import Vue from 'vue';
import VeeValidate from 'vee-validate';

const phoneValidate = {
  getMessage(field, args) {
    return ``;
  },
  validate(value, args) {
    const PHONEREG = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){7,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
    return PHONEREG.test(value);
  },
};

VeeValidate.Validator.extend('phoneNumber', phoneValidate);

Vue.use(VeeValidate);
