import merge from 'lodash.merge';

import { apiCall, SINGLE, MULTIPLE } from '~/api';
import { processSingle, processMultiple } from '~/utils/processor';
import { getError, handleKenticoError } from '~/utils/errors';
import templates from '~/.templates';
import { API_DEPTH } from '~/utils/constants';

export function validPath(path = '') {
  if (path.length > 0) {
    if (path.includes('json')) {
      return false;
    }

    return true;
  }

  return false;
}

export function getSlug(path) {
  const pathSplit = path.split('/');

  let pathName = pathSplit[pathSplit.length - 1];

  if (pathName.length === 0) {
    pathName = pathSplit[pathSplit.length - 2];
  }

  if (pathName) {
    return pathName;
  }

  return path;
}

export async function setupAdditionalRequests(requests, foundModules, forceAdditional = false, debug = false) {
  const requestStore = requests.reduce((store, request) => {
    const moduleInData = foundModules.find((moduleObj) => moduleObj.key === request.moduleKey);

    if (moduleInData || forceAdditional) {
      const { contentType, injectKey, options } = request;
      const { type, modifiers } = options;

      return [
        ...store,
        apiCall(
            {
              type,
              contentType,
              modifiers: {
                depth: API_DEPTH,
                ...modifiers,
              },
              dataProcessor: (data) => {
                let processedData = null;

                if (type === SINGLE && typeof data.item !== 'undefined') {
                  processedData = processSingle(data).content;
                }
                if (type === MULTIPLE && typeof data.items !== 'undefined') {
                  processedData = processMultiple(data);
                }

                if (forceAdditional) {
                  return {
                    [injectKey]: processedData,
                  };
                }

                return {
                  [moduleInData.key]: {
                    [injectKey]: processedData,
                  },
                };
              },
            },
            debug
        ),
      ];
    }

    return store;
  }, []);

  const resolvedRequests = await Promise.all(requestStore);
  const collectedData = resolvedRequests.reduce((store, item) => {
    return {
      ...store,
      ...item,
    };
  }, {});

  return collectedData;
}

export function getSlugToQuery(path) {
  const isValidPath = validPath(path);

  if (isValidPath && path.length > 0) {
    return getSlug(path);
  }

  return false;
}

export async function addAdditionalData(content, additionalRequests, modules, forceAdditional = false) {
  // If there are modules on template OR we aren't on a page template (a module page), get addtional data
  // Merge as addtional data is added deeply

  if (modules.length > 0 || forceAdditional) {
    const additionalData = await setupAdditionalRequests(additionalRequests, modules, forceAdditional);
    return merge(content, additionalData);
  }

  return content;
}

export function getTemplateAddtionalRequests(templateObj) {
  if (typeof templateObj !== 'undefined' && typeof templateObj.additionalRequests !== 'undefined') {
    return [...templateObj.additionalRequests()];
  }

  return [];
}

export async function generateReturnedData(templateObj, modules, content, additionalRequests, forceAdditional) {
  const additionalRequesCollection = [...additionalRequests, ...getTemplateAddtionalRequests(templateObj)];
  let returnContent = content;

  if (additionalRequesCollection.length > 0) {
    returnContent = await addAdditionalData(returnContent, additionalRequesCollection, modules, forceAdditional);
  }

  return {
    data: {
      ...returnContent,
    },
    template: templateObj ? templateObj.key : null,
  };
}

export async function asyncApiData(options = {}) {
  const defaultError = getError(500);

  const {
    path = '',
    nuxtError,
    models = [],
    contentItem,
    additionalRequests = [],
    forceAdditional = false,
    modulePage = false,
  } = options;

  try {
    const slug = getSlugToQuery(path);

    if (contentItem || slug) {
      const { contentType, modules, content } = await apiCall({
        type: SINGLE,
        slug,
        contentItem,
        modifiers: {
          depth: API_DEPTH,
        },
        models,
        dataProcessor: (data) => {
          return processSingle(data);
        },
      });

      const templateObj = templates.find((template) => template.contentType === contentType);

      if (templateObj || modulePage) {
        const data = await generateReturnedData(templateObj, modules, content, additionalRequests, forceAdditional);
        return data;
      }
    } else if (validPath(path)) {
      console.error('No content item or slug');
    }
  } catch (errorObj) {
    const { errorCode } = errorObj;

    console.error(errorObj);

    if (errorCode) {
      return nuxtError(handleKenticoError(errorCode, defaultError));
    }

    return nuxtError(defaultError);
  }

  // Fall back to server error;

  return nuxtError(defaultError);
}
