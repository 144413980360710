import { ContentItem } from 'kentico-cloud-delivery';

/**
 * This class was generated by 'kentico-cloud-model-generator-utility' at Wed Dec 05 2018 11:24:03 GMT+0000 (Greenwich Mean Time).
 */
class NavigationItem extends ContentItem {
  constructor() {
    super({
      propertyResolver: (fieldName) => {
        if (fieldName === 'content_item') {
          return 'contentItem';
        }
        if (fieldName === 'redirect_to_url') {
          return 'redirectToUrl';
        }
        if (fieldName === 'secondary_text') {
          return 'secondaryText';
        }
        if (fieldName === 'tertiary_text') {
          return 'tertiaryText';
        }

        return fieldName;
      },
    });
  }
}

export default NavigationItem;
