import Uniqid from 'uniqid';

export const STAY_LONG = 'long stay';
export const STAY_SHORT = 'short stay';
export const TOUR = 'tour';
export const EVENT_CUSTOM_CLICK = 'customClickEvent';
export const CATEGORY_ENQUIRY = 'Enquiry';
export const CATEGORY_BOOKING = 'Booking';

export function trackStay(gtm, location, length) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_ENQUIRY,
    action: 'Book a stay',
    label: `${location} - ${length}`,
  });
}

export function trackTour(gtm, location, length) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_ENQUIRY,
    action: 'Book a tour',
    label: `${location} - ${length}`,
  });
}

export function trackRoomClick(gtm, actionName, location) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_BOOKING,
    action: actionName,
    label: location,
  });
}

export function trackStayLength(gtm, actionName, location) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_BOOKING,
    action: actionName,
    label: location,
  });
}

export function trackBookingType(gtm, actionName, location) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_BOOKING,
    action: actionName,
    label: location,
  });
}

export function trackFormInput(gtm, actionName, location) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_BOOKING,
    action: actionName,
    label: location,
  });
  // console.log(window.dataLayer);
}

export function trackFormSubmit(gtm, actionName, location) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_BOOKING,
    action: actionName,
    label: location,
  });
}

export function trackEnquiry(gtm, values) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    category: CATEGORY_ENQUIRY,
    action: 'EnquiryFormSubmitted',
    name: values.name,
    phoneNumber: values.phone,
    email: values.email,
    contactTime: values.selectedTime,
    contactMethod: values.selectedMethod,
    location: values.preferred_location,
    comments: values.comments,
  });
}

export function trackNewsletter(gtm, email) {
  pushEvent(gtm, {
    event: 'signedUpToNewsletter',
    email: email,
  });
}

export function trackArrivalDate(gtm, arrivalDate, fldate = '') {
  pushEvent(gtm, {
    event: 'arrivalDateSelected',
    date: arrivalDate,
    fldate,
  });
}

export function captureUserBudget(gtm, budget) {
  pushEvent(gtm, {
    event: 'userBudget',
    userBudget: budget,
  });
}

export function submittedStayRequest(gtm, data, selectedDate) {
  pushEvent(gtm, {
    event: 'submittedStayRequest',
    preferredArrivalDate: selectedDate,
    firstname: data.fname,
    lastname: data.lname,
    phone: data.phone,
    email: data.email,
    preferredContactMethod: data.selectedMethod,
    preferredContactTime: data.selectedTime,
    userBudget: data.monthlyBudget,
  });
}

export function submittedTourRequest(gtm, data, selectedDate) {
  pushEvent(gtm, {
    event: 'submittedTourRequest',
    date: selectedDate,
    time: data.selectedTime,
    name: data.fname + ' ' + data.lname,
    phone: data.phone,
    email: data.email,
  });
}

export function selectedFilter(gtm, selectedFilter) {
  pushEvent(gtm, {
    event: 'selectedFilter',
    filter: selectedFilter,
  });
}

export function clickedShowMe(gtm, showMeMessage) {
  pushEvent(gtm, {
    event: 'clickedShowMe',
    action: showMeMessage,
  });
}

export function clickedFaq(gtm, faqTitle) {
  pushEvent(gtm, {
    event: 'clickedFaq',
    question: faqTitle,
  });
}

export function clickedNavLink(gtm, action) {
  pushEvent(gtm, {
    event: 'clickedNavLink',
    action: action,
  });
}

export function clickedPlayVideo(gtm, cardTitle) {
  pushEvent(gtm, {
    event: 'clickedPlayVideo',
    title: 'Video for ' + cardTitle + 'resident card',
  });
}

export function clickedCallToActionButton(gtm, action) {
  pushEvent(gtm, {
    event: EVENT_CUSTOM_CLICK,
    action: action,
  });
}

export function clickedCta(gtm, action, location, room, target) {
  pushEvent(gtm, {
    event: 'clickedCta',
    action: action,
    location: location,
    room: room,
    target: target,
  });
}

// New CTA Tracking
export function trackClickedCta(gtm, eventData) {
  const { event } = eventData;
  const data = { ...eventData, event: event ? event : EVENT_CUSTOM_CLICK };
  pushEvent(gtm, data);
}

export function eventSpaceEnquire(gtm, action) {
  pushEvent(gtm, {
    event: 'clickedCta',
    action: action,
  });
}

export function viewedCarousel(gtm, carouselTitle, screenTitle, screenNumber, totalScreens, slidesPerView) {
  pushEvent(gtm, {
    event: 'viewedCarousel',
    carouselTitle: carouselTitle,
    screenTitle: screenTitle,
    screenNumber: screenNumber,
    totalScreens: totalScreens,
    slidesPerView: slidesPerView,
  });
}

// floodlight tags

// called on submit of canary wharf tour option
export function conversionCanaryWharfTour(gtm) {
  pushEvent(gtm, {
    event: 'conversion',
    allow_custom_scripts: true,
    send_to: `DC-9920711/websi984/canar234${Uniqid()}`,
  });
}

// called on submit of canary wharf direct option
export function conversionCanaryWharfDirect(gtm) {
  pushEvent(gtm, {
    event: 'conversion',
    allow_custom_scripts: true,
    send_to: `DC-9920711/websi984/canar123${Uniqid()}`,
  });
}

// called on submit of old oak tour option
export function conversionOldOakTour(gtm) {
  pushEvent(gtm, {
    event: 'conversion',
    allow_custom_scripts: true,
    send_to: `DC-9920711/websi984/oldoa234${Uniqid()}`,
  });
}

// called on submit of old oak direct option
export function conversionOldOakDirect(gtm) {
  pushEvent(gtm, {
    event: 'conversion',
    allow_custom_scripts: true,
    send_to: `DC-9920711/websi984/olda123${Uniqid()}`,
  });
}

// called on submit of old oak direct option
export function floodlightTrackPage(gtm, path) {
  pushEvent(gtm, {
    event: 'conversion',
    allow_custom_scripts: true,
    u9: `[${path}]`,
    send_to: `DC-9920711/websi984/colle123${Uniqid()}`,
  });
}

function pushEvent(gtm, event) {
  if (gtm && gtm.pushEvent) {
    gtm.pushEvent(event);
  }
}
