import { ContentItem } from 'kentico-cloud-delivery';

/**
 * This class was generated by 'kentico-cloud-model-generator-utility' at Mon Jan 07 2019 11:30:12 GMT+0000 (Greenwich Mean Time).
 */
class Address extends ContentItem {
  constructor() {
    super({
      propertyResolver: (fieldName) => {
        if (fieldName === 'address_line_two') {
          return 'addressLineTwo';
        }
        if (fieldName === 'phone_number') {
          return 'phoneNumber';
        }
        if (fieldName === 'address_line_one') {
          return 'addressLineOne';
        }
        return fieldName;
      },
    });
  }
}

export default Address;
