export const SERVER_500_MESSAGE = 'There was a error on the server';
export const SERVER_404_MESSAGE = 'Page not found';
export const SERVER_MODULE_404_MESSAGE = 'Module not found';

export function generateErrorObj(statusCode, message) {
  return {
    statusCode,
    message,
  };
}

export function getError(statusCode) {
  switch (statusCode) {
    case 404: {
      return generateErrorObj(statusCode, SERVER_404_MESSAGE);
    }
    case 500: {
      return generateErrorObj(statusCode, SERVER_500_MESSAGE);
    }
    default: {
      return generateErrorObj(statusCode, SERVER_500_MESSAGE);
    }
  }
}

export function handleKenticoError(errorCode, defaultError) {
  switch (errorCode) {
    case 100: {
      return getError(404);
    }
    default: {
      return defaultError;
    }
  }
}
