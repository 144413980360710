/* eslint-disable no-use-before-define */

import camelCase from 'lodash.camelcase';
import { Fields, richTextResolver, getParserAdapter, Link } from 'kentico-cloud-delivery';
import templates from '~/.templates';
import { RECURSIVE_DEPTH_LIMIT } from '~/utils/constants';

export const FIELD_MODULAR_CONTENT = 'modular_content';
export const FIELD_ASSET = 'asset';
export const FIELD_MULTIPLE_CHOICE = 'multiple_choice';
export const FIELD_URL_SLUG = 'url_slug';
export const FIELD_RICH_TEXT = 'rich_text';
export const SINGLE_ASSETS = ['image', 'icon', 'featuredImage', 'aggregatorImage', 'avatar'];
export const TRANSFORM_KEYS = [
  {
    from: 'meta_fcf1319__meta_title',
    to: 'meta_title',
  },
  {
    from: 'meta_fcf1319__meta_description',
    to: 'meta_description',
  },
];

export const SINGLE_LINKED = [
  'contentItem',
  'callToActionLink',
  'link',
  'video',
  'googleMap',
  'redirect',
  'redirectTo',
  'address',
  'header',
  'homepage',
  'cookieBanner',
  'footer',
];

export const SINGLE_LINKED_SITE = [
  'bookPage',
  'primaryNavigationPrimary',
  'primaryNavigationSecondary',
  'newsletterSignup',
  'menuOne',
  'menuTwo',
  'menuThree',
];

const TEMPLATE_CONTENT_TYPES = templates.map((item) => item.contentType);

export function forceObject(data) {
  if (data.length === 1) {
    return data[0];
  }

  return data;
}

export function moduleHasData(data) {
  if (!!data.length || Object.keys(data).length > 0) {
    return true;
  }

  return false;
}

export function convertLinkObj(linkObj = {}) {
  const preppedLinks = Object.keys(linkObj).map((key) => {
    const thisLink = linkObj[key];
    const kenticoLink = new Link({
      itemId: key,
      codename: thisLink.codename,
      type: thisLink.type,
      urlSlug: thisLink.url_slug,
    });

    return kenticoLink;
  });

  return preppedLinks;
}

export function convertRawRichTextToKenticoRichText(name, value, links) {
  const richText = new Fields.RichTextField(name, value, [], {
    links,
    resolveHtml: () =>
      richTextResolver.resolveHtml(value, {
        links,
        richTextHtmlParser: getParserAdapter(),
        queryConfig: {
          linkResolver: linkProcessor,
        },
      }),
  });

  return richText;
}

export function processAssetArray(assetArray, key) {
  if (SINGLE_ASSETS.includes(key) && assetArray.length === 1) {
    return assetArray[0];
  }

  if (SINGLE_ASSETS.includes(key) && assetArray.length === 0) {
    return null;
  }

  return assetArray;
}

export function tidyUpChoices(multipleChoiceArray) {
  return multipleChoiceArray.map((item) => ({
    ...item,
    codename: camelCase(item.codename),
  }));
}

export function processRichText(key, field, modularContent, element) {
  let processField = field;

  if (!processField) {
    processField = convertRawRichTextToKenticoRichText(
        key,
        element.value,
        convertLinkObj(element.links),
        linkProcessor
    );
  }

  const { value } = processField;

  if (value && value.length > 0 && value !== '<p><br></p>') {
    if (processField.linkedItemCodenames.length > 0) {
      const modularValues = processField.linkedItemCodenames;

      const richContent = modularValues.reduce((itemStore, codename) => {
        const content = modularContent[codename];

        if (content) {
          const { elements } = processElements(content, content.elements, modularContent);

          return [
            ...itemStore,
            {
              ...elements,
              contentType: content.system.type,
              contentCodename: content.system.codename,
            },
          ];
        }

        return itemStore;
      }, []);

      return richContent;
    }

    return processField.getHtml();
  }

  return null;
}

// Return first object of array, rather than array itself if content type is a template or if key matches store

export function postProcessModularContent(modularContentArray, key, contentType, depth) {
  if (
    modularContentArray.length === 1 &&
    (TEMPLATE_CONTENT_TYPES.includes(contentType) || SINGLE_LINKED.includes(key) || SINGLE_LINKED_SITE.includes(key))
  ) {
    return modularContentArray[0];
  }

  return modularContentArray;
}

export function processMultipleChoice(multipleChoiceArray) {
  return tidyUpChoices(multipleChoiceArray);
}

export function processUrlSlug(slug, contentType) {
  const templateObj = templates.find((template) => template.contentType === contentType);

  if (templateObj && typeof templateObj.prependSlug !== 'undefined') {
    const prefixed = `/${templateObj.prependSlug}${slug}`;
    return prefixed;
  }

  return `/${slug}`;
}

export function linkProcessor(link, context) {
  const url = processUrlSlug(link.urlSlug, link.type);
  const { linkText: text } = context;

  return {
    asHtml: `<a href="${url}">${text}</a>`,
  };
}

// Get child field content by codename

export function getContentByCodename(data, key, codename) {
  let dataKey = key;

  if (data.propertyResolver) {
    dataKey = data.propertyResolver(key);
  }

  if (data[dataKey]) {
    const content = data[dataKey].find((item) => item.system.codename === codename);

    if (content) {
      return content;
    }
  } else {
    console.log('could not get nested data');
    console.info('data');
    console.log(data);
    console.info('key');
    console.log(key);
    console.log('data with key');
    console.log(data[key]);
    console.log('codename');
    console.log(codename);
  }

  return false;
}

export function processModularContent(parent, key, modularValues = [], modularContentStore, depth) {
  const moduleData = modularValues.reduce((itemStore, codename) => {
    const content = getContentByCodename(parent, key, codename);

    if (content) {
      const { elements } = processElements(content, content.elements, modularContentStore, depth);

      return [
        ...itemStore,
        {
          ...elements,
          contentType: content.system.type,
          contentCodename: content.system.codename,
        },
      ];
    }

    return itemStore;
  }, []);

  return moduleData;
}

export function getElementValue(parentData, elementData) {
  if (typeof parentData !== 'undefined' && typeof parentData.value !== 'undefined') {
    return parentData.value;
  }

  return elementData.value;
}

export function generateReturnKey(key) {
  const transformKey = TRANSFORM_KEYS.find((keyObj) => keyObj.from === key);

  if (typeof transformKey !== 'undefined') {
    return camelCase(transformKey.to);
  }

  return camelCase(key);
}

export function processElements(parent, elements = {}, modularContentStore = {}, depth = 0) {
  if (depth > RECURSIVE_DEPTH_LIMIT) {
    // console.warn('recursion limit hit', depth);
    return {};
  }
  let modules = [];
  const contentType = parent.system.type;

  const processedElements = Object.keys(elements).reduce((store, key) => {
    const returnKey = generateReturnKey(key);
    const element = elements[key];
    const field = parent[key];

    const { type, value: elementValue } = element;
    const value = getElementValue(field, element);

    switch (type) {
      case FIELD_MODULAR_CONTENT: {
        const modularContent = processModularContent(parent, key, elementValue, modularContentStore, depth + 1);

        if (modularContent.length > 0) {
          // Add to modules array, if there is data
          modules = [
            ...modules,
            {
              key: returnKey,
            },
          ];

          return {
            ...store,
            [returnKey]: postProcessModularContent(modularContent, returnKey, contentType, depth + 1),
          };
        }

        return {
          ...store,
        };
      }
      case FIELD_MULTIPLE_CHOICE: {
        return {
          ...store,
          [returnKey]: processMultipleChoice(value),
        };
      }
      case FIELD_ASSET: {
        return {
          ...store,
          [returnKey]: processAssetArray(value, returnKey),
        };
      }
      case FIELD_URL_SLUG: {
        return {
          ...store,
          [returnKey]: processUrlSlug(value, contentType),
        };
      }
      case FIELD_RICH_TEXT: {
        return {
          ...store,
          [returnKey]: processRichText(key, field, modularContentStore, element),
        };
      }
      default: {
        return {
          ...store,
          [returnKey]: value,
        };
      }
    }
  }, {});

  return {
    elements: processedElements,
    modules,
    contentType,
  };
}

// Process page data into modules and meta keys

export function processSingle(data = {}, debug = false) {
  const { item, modularContent } = data;
  const { elements: content, modules, contentType } = processElements(item, item.elements, modularContent);

  if (debug) {
    console.log('page data');
    console.log(content);
  }

  return {
    content,
    modules,
    contentType,
  };
}

export function processMultiple(data) {
  if (typeof data.items !== 'undefined' && typeof data.pagination !== 'undefined') {
    const processedItems = data.items.map((item) => {
      const singleItem = processSingle({
        item,
        modularContent: data.modularContent,
      });

      return singleItem.content;
    });

    return {
      pagination: data.pagination,
      data: processedItems,
    };
  }

  return false;
}
